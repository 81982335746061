import React from 'react';
import Hero from 'components/Hero';
import MissionStatement from 'components/MissionStatement';
import AppLinks from 'components/AppLinks';
import SmartShopFooter from 'components/SmartShopFooter';
import HowTo from 'components/HowTo';
import FAQs from 'components/FAQs';
import Reviews from 'components/Reviews';
import { Container } from 'luna-react';
import shuffle from './shuffler'
import reviews from './data/reviews'
import faqs from './data/faqs'
import TAndCLinks from "./components/TAndCLinks";

const App = () => (
  <>
    <Hero />
    <MissionStatement />
    <HowTo />
    <Reviews reviews={shuffle(reviews)} />
    <div className="divider" />
    <FAQs faqs={faqs} />
    <Container style={{ paddingLeft: 0, paddingRight: 0 }} size="xs">
      <TAndCLinks />
    </Container>
    <Container style={{ paddingLeft: 0, paddingRight: 0 }} size="xs">
      <AppLinks large />
    </Container>
    <SmartShopFooter />
  </>
);

export default App;
