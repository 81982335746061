import React from 'react';
import 'matchmedia-polyfill';
import Slider from 'react-slick';
import { Container } from 'luna-react';
import Review from './Review'
import 'styles/reviews.scss';

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: false,
};

const Reviews = ({reviews}) => (
  <div className="reviews">
    <Container className="ln-u-soft" soft size="xs">
      <Slider {...settings}>
        {reviews.map((data) => (
          <Review key={data.id} {...data} />
        ))}
      </Slider>
    </Container>
  </div>
);

export default Reviews;
