import React from 'react';
import Header from 'components/Hero/Header';
import 'styles/hero.scss';

const Hero = () => (
  <div className="hero">
    <Header />
    <img
      srcSet="img/Zombie-hand_Mobile.png 269w, img/Zombie-hand_Desktop.png 560w"
      sizes="(max-width: 720px) 269px, 560px"
      src="img/Zombie-hand_Desktop.png"
      alt="Scan &amp; bag as you go"
    />
  </div>
);

export default Hero;
