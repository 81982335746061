import React from 'react';
import 'matchmedia-polyfill';
import { Favourites, FavouritesFilled } from 'luna-icons';
import 'styles/reviews.scss';
import PropTypes from 'prop-types';

const Stars = ({rating}) => (
    <div className="stars">
      {[...Array(rating).keys()].map(i => (
        <FavouritesFilled key={i} size="large" />
      ))}
      {[...Array(5 - rating).keys()].map(i => (
        <Favourites key={i} size="large" />
      ))}
    </div>
);

Stars.propTypes = {
    rating: PropTypes.number.isRequired
}

export default Stars