import React from 'react';
import PropTypes from 'prop-types';
import DownloadOnTheAppStore from 'components/AppLinks/DownloadOnTheAppStore';
import GetItOnGooglePlay from 'components/AppLinks/GetItOnGooglePlay';

const AppLinks = (props) => {
  const { large } = props;

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <DownloadOnTheAppStore large={large} />
        <GetItOnGooglePlay large={large} />
      </div>
    </>
  );
};

AppLinks.defaultProps = {
  large: false,
};

AppLinks.propTypes = {
  large: PropTypes.bool,
};

export default AppLinks;
