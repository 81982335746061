import React from 'react';
import 'styles/how-to.scss';

const steps = [
  'Download the app or register in store at the handset wall — you’ll need your Nectar card handy',
  'Scan and bag as you go (either bring your own bags or pick them up at the entrance)',
  'Pay at a dedicated SmartShop checkout and you’re done!',
];

const HowToSteps = () => (
  <div className="step-container">
    <h4>Here&#39;s how to shop smarter</h4>

    {steps.map((stepText, i) => (
      <div className="steps" key={stepText.charAt(0)}>
        <span className="step-number ln-u-display-7">{i + 1}</span>
        <span className="step-body ln-u-display-1">{stepText}</span>
      </div>
    ))}
  </div>
);

export default HowToSteps;
