import { Container } from 'luna-react';
import React from 'react';
import 'styles/mission-statement.scss';

const MissionStatement = () => (
  <Container className="mission-statement ln-u-soft" size="xs">
    <h3 className="ln-u-color-orange ln-u-text-align-center">
      SmartShop is the new way to shop at Sainsbury&#39;s.
      <br />
      Just scan, bag and go, it couldn&#39;t be easier!
    </h3>
  </Container>
);

export default MissionStatement;
