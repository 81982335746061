import React from 'react';
import PropTypes from 'prop-types';
import 'styles/app-store-badges.scss';
import { logFirebaseEvent } from 'analytics/firebaseAnalytics';
import { FIREBASE_EVENT_CLICK_APP_STORE } from 'analytics/eventConstants';

const DownloadOnTheAppStore = (props) => {
  const { large } = props;
  const sizeClassName = large ? 'large' : '';

  return (
    <div className={`download-on-the-app-store ${sizeClassName}`}>
      <a href="https://go.onelink.me/app/SSiOS" onClick={() => logFirebaseEvent(FIREBASE_EVENT_CLICK_APP_STORE)}>
        <img
          alt="Download on the App Store"
          src="/img/Download_on_the_App_Store_Badge.svg"
        />
      </a>
    </div>
  );
};

DownloadOnTheAppStore.defaultProps = {
  large: false,
};

DownloadOnTheAppStore.propTypes = {
  large: PropTypes.bool,
};

export default DownloadOnTheAppStore;
