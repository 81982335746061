import * as firebase from 'firebase/app';
import 'firebase/analytics';

export const setupFirebaseAnalytics = () => {
  if (window.env.firebaseAnalytics.enabled) {
    // TODO: Replace the following with your app's Firebase project configuration
    const firebaseConfig = {
      apiKey: window.env.firebaseAnalytics.apiKey,
      authDomain: window.env.firebaseAnalytics.authDomain,
      databaseURL: window.env.firebaseAnalytics.databaseURL,
      projectId: window.env.firebaseAnalytics.projectId,
      storageBucket: window.env.firebaseAnalytics.storageBucket,
      messagingSenderId: window.env.firebaseAnalytics.messagingSenderId,
      appId: window.env.firebaseAnalytics.appId,
      measurementId: window.env.firebaseAnalytics.measurementId,
    };

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    window.firebase = firebase;
  }
};

export const logFirebaseEvent = (event) => {
  if (window.env.firebaseAnalytics.enabled) {
    const analytics = window.firebase.analytics();
    analytics.logEvent(event);
  }
};
