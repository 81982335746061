import React from 'react';
import {
  Container, FlagBody, FlagComponent, FlagWrapper, Footer,
} from 'luna-react';

const links = [
  'Privacy policy',
  'Cookies policy',
  'Accessibility',
  'Terms of use',
];

const linkUrls = [
  'https://privacy-hub.sainsburys.co.uk/privacy-policy/',
  'https://privacy-hub.sainsburys.co.uk/cookie-policy/',
  'https://help.sainsburys.co.uk/help/website/our-commitment',
  'https://help.sainsburys.co.uk/help/website/terms-conditions-acceptance',
];

const SmartShopFooter = () => (
  <Footer>
    <Container>
      <FlagWrapper respondAt="sm" className="ln-u-caption">
        <FlagBody className="ln-u-push-right-lg">
          <span className="ln-u-display-block ln-u-font-weight-bold">
            Sainsbury&rsquo;s, Live Well For Less
          </span>
          &copy; J Sainsbury plc&nbsp;
          {new Date().getFullYear()}
        </FlagBody>
        <FlagComponent alignment="top">
          <div className="ln-u-display-flex ln-u-flex-wrap">
            {links.map((link, index) => (
              <span
                className="ln-u-display-flex ln-u-align-items-center ln-u-text-align-center"
                key={link}
              >
                <a href={linkUrls[index]} className="ln-o-bare-link">
                  {link}
                </a>
                {index !== links.length - 1 && (
                  <span className="ln-u-push-sides-sm">|</span>
                )}
              </span>
            ))}
          </div>
        </FlagComponent>
      </FlagWrapper>
    </Container>
  </Footer>
);

export default SmartShopFooter;
