import React from 'react';
import PropTypes from 'prop-types';
import 'styles/app-store-badges.scss';
import { FIREBASE_EVENT_CLICK_GOOGLE_PLAY } from 'analytics/eventConstants';
import { logFirebaseEvent } from 'analytics/firebaseAnalytics';

const GetItOnGooglePlay = (props) => {
  const { large } = props;
  const sizeClassName = large ? 'large' : '';

  return (
    <div className={`get-it-on-google-play ${sizeClassName}`}>
      <a name="google-play-link" href="https://go.onelink.me/app/SSAnd" onClick={() => logFirebaseEvent(FIREBASE_EVENT_CLICK_GOOGLE_PLAY)}>
        <img
          alt="Get it on Google Play"
          src="/img/google-play-badge.png"
        />
      </a>
    </div>
  );
};

GetItOnGooglePlay.defaultProps = {
  large: false,
};

GetItOnGooglePlay.propTypes = {
  large: PropTypes.bool,
};

export default GetItOnGooglePlay;
