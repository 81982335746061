import React from 'react';
import { Container } from 'luna-react';
import HowToSteps from 'components/HowTo/HowToSteps';
import 'styles/how-to.scss';

const HowTo = () => (
  <div className="grey-bg">
    <Container className="how-to-container ln-u-soft" soft size="xs">
      <HowToSteps />
      <img
        className="ghost-hand"
        src="img/handset-soap.png"
        alt="BEEP!"
      />
    </Container>
  </div>
);

export default HowTo;
