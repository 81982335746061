import React from 'react';
import AppLinks from 'components/AppLinks';
import 'styles/header.scss';

const Header = () => (
  <div className="header-container">
    <img className="logo" src="img/SS-Logo.svg" alt="Smartshop" />
    <div className="applinks-container">
      <AppLinks />
    </div>
  </div>
);

export default Header;
