import React from 'react';

const TAndCLinks = (props) => {
  return (
    <>
      <div >
        <h2>Terms and Conditions</h2>
        <p>
          <a href="https://help.sainsburys.co.uk/help/terms-and-conditions/smart-shop-terms">
            SmartShop terms & conditions
          </a>
        </p>
        <p>
          <a href="https://www.nectar.com/about/privacy-and-legal/my-nectar-prices">
            Nectar Prices terms & conditions
          </a>
        </p>
      </div>
    </>
  );
};

export default TAndCLinks;
