import React from 'react';
import { AccordionItem, Container } from 'luna-react';
import CryptoJS from 'crypto-js';
import 'styles/faqs.scss';
import { logFirebaseEvent } from 'analytics/firebaseAnalytics';
import ISSAAccordion from './ISSAAccordion';
import * as eventConstants from '../analytics/eventConstants';

const hash = str => CryptoJS.MD5(str).toString();

const logFAQExpandedToFirebase = (closed, faqIndex) => {
  if (!closed) {
    const eventName = eventConstants.getFirebaseExpandEventFAQ(faqIndex);
    logFirebaseEvent(eventName);
  }
};

const FAQs = ({ faqs }) => (
  <div className="grey-bg">
    <Container className="faqs ln-u-soft" soft size="xs">
      <h2>FAQs</h2>
      <ISSAAccordion titleElement="h3">
        {
          faqs.map((faq, i) => (
            <AccordionItem id={`faq-${i}`} key={hash(faq.a)} title={faq.q} onClick={(closed, index) => logFAQExpandedToFirebase(closed, index)}>
              {faq.a.split('\n').map(answerParagraph => <p key={hash(answerParagraph)} className="faq">{answerParagraph}</p>)}
            </AccordionItem>
          ))
        }
      </ISSAAccordion>
    </Container>
  </div>
);

export default FAQs;
