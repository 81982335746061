import React from 'react';
import 'matchmedia-polyfill';
import 'styles/reviews.scss';
import PropTypes from 'prop-types';
import Stars from './Stars';


const Review = (props) => {
    const {
        rating,
        title,
        body,
        version
    } = props;

    return (<div>
        <Stars rating={rating} />
        <p className="review-block">
            <strong>{`"${title}"`}</strong>
            {` ${body} `}
            <em>{`- An ${version} user`}</em>
        </p>
    </div>)
}

Review.propTypes = {
  rating: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired
}

export default Review